<template>
  <div>
    <div class="header-slot">
      <div class="align-center-content">
        <div class="app-store-title">应用商店</div>
        <div class="app-store-description">
          KubeStar官方应用商店，基于Helm Charts，支持上传Charts，用户可以根据需求快速一键部署
        </div>

        <el-input v-model="applicationName" placeholder="查找应用" class="app-search-input"></el-input>
      </div>

      <div class="upload-tips">
        <div class="upload-tips-title">上传应用至应用商店</div>
        <div class="upload-tips-description">
          KubeStar应用商店支持上传Charts，点击下方的上传按钮，即可上传Charts文件
        </div>

        <el-upload
          action="http://172.17.17.104:8080/api/charts"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          :on-error="uploadError"
        >
          <el-button size="small" type="primary" class="upload-button" :loading="uploadLoading">点击上传</el-button>
        </el-upload>
      </div>
    </div>

    <div class="clearfix" v-loading="loading">
      <div
        v-for="item in appList"
        :key="item.name"
        class="app-item-container"
        @click="toDetail(item.name, item.version)"
      >
        <div class="app-main">
          <el-image :src="item.icon" alt="" />

          <div class="app-main-info">
            <div class="title">{{ item.name }}</div>
            <div class="desc">{{ item.description }}</div>
          </div>
        </div>

        <div class="app-version">
          <span class="desc">当前版本: {{ item.version }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { charts } from "api/appstore";
export default {
  data() {
    return {
      applicationName: "",
      uploadLoading: false,
      appList: [],
      loading: false
    };
  },

  methods: {
    beforeUpload() {
      this.uploadLoading = true;
    },

    uploadSuccess() {
      this.uploadLoading = false;
    },

    uploadError(err) {
      this.uploadLoading = false;
      console.log(err);
    },

    getCharts() {
      this.loading = true;
      charts().then(response => {
        this.loading = false;
        if (response) {
          for (let item in response) {
            this.appList.push({
              name: item,
              version: response[item][0].version,
              description: response[item][0].description,
              versionNumber: response[item].length,
              icon: response[item][0].icon
            });
          }
        }
      });
    },

    toDetail(name, version) {
      this.$router.push({
        path: `/appstore/detail/${name}/${version}`
      });
    }
  },

  mounted() {
    this.getCharts();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.app-item-container {
  float: left;
  transition: 0.3s all ease;
  width: 300px;
  box-shadow: $common-shadow;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;

  .app-main {
    position: relative;

    .el-image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .app-main-info {
      margin-left: 60px;
      margin-bottom: 6px;

      .title {
        @include text-overflow();
      }

      .desc {
        height: 40px;
        overflow: hidden;
      }
    }
  }

  &:hover {
    border-color: #314659;
    cursor: pointer;
  }
}
</style>
