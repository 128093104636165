import http from "../appstore";

export const charts = () => {
  return http({
    method: "get",
    url: `/charts`
  });
};

export const chartVersions = name => {
  return http({
    method: "get",
    url: `/charts/${name}`
  });
};

export const chartVersionDescribe = (name, version) => {
  return http({
    method: "get",
    url: `/charts/${name}/${version}`
  });
};
