import axios from "axios";

const http = axios.create({
  baseURL: "http://172.17.17.110:8080/api"
});

http.defaults.headers.post["Content-Type"] = "application/json";

http.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default http;
